
<template>
  <div class="cookie" >
    <h1>Privacy Policy</h1>

      <p>Personal WebSite, accessible from https://haniftadjik.com, one of our main priorities is our users' privacy.
      For us, it is important that you have control over your personal information and your privacy is preserved. This
      document contains types of information that we collect and record and how we use it.</p>

      <h3>Data that we collect and process</h3>
      <ul>
        <li>The personal data that you provide to our website when you voluntarily fill out our contact form.
          The personal data can includes name, last name, Email address, phone number, as well as any other content included in your message or registration.
         </li>
        <li>The personal information that you provide for registering for our blogs and newsletters.
          When you subscribe or register for the newsletter and blogs, we will send you regular emails. But,
          you will always have the option to unsubscribe from our email list.
        </li>
        <li>The data that we collect via technology.
          When you use our website, we may collect device and browser metadata including, but not limited to your operating
          system type and version, mobile device ID, Internet Protocol address, time of access, the URLs of the pages you visited, and for how long you
          visited those pages. We use these informations to persnoalize your experience every time you visit our website and provide better services.
        </li>
        <li>Public Information that we collect from Blockchains. We collect data that are publicly transparent on blockchains. This data can include any
          blockchain addresses, mint, sale, burn, or buying of fungible or non-fungible tokens (NFTs). We use this data to provide you with services related
          to blockchains.
        </li>
      </ul>

      <h3>Use of your personal data</h3>
      <p>
        We use your personal data to provide you services, personalize your experience, run our bussiness, and improve our system.<br>
        We will keep your personal information only for as long as is necessary for the purposes described in this Privacy Policy.
          We will retain and use your information to the extent necessary to comply with our legal duties, resolve disagreements, and enforce our policies.
          However, whenever any data is not necessary for our services, we will remove the data from our storage systems.
      </p>

      <h3>Residents of the European Economic Area (EEA)</h3>
      <p>
        If you are a resident of the European Economic Area (EEA), you have the right to be informed of what Personal Information we hold about you. You have
          also the right to choose if you want to remove your personal information from our systems. For any of the above cases, please contact us.
      </p>

      <h3>Tracking User Behavior</h3>
      <p>
          We follow a standard procedure of using log files. These files log visitors when they visit websites.
          All hosting companies do this and are a part of hosting services' analytics. The information collected by log files includes
          internet protocol (IP) addresses, Internet Service Provider (ISP), date and time stamp, type of browser, referring or exit pages,
          and possibly the number of clicks. These are not linked to any personally identifiable information.
          The purpose of the information is to analyze trends, administering the site, tracking users' movement on the website,
          to maintain the quality of the service, and to provide general statistics regarding the use of the website.
      </p>

      <h3>Use of Cookies</h3>
      <p>
        We use cookies to personalize your experience and provide you services with better quality. Cookies are data that web servers store on your device.
        We use these cookies to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information
        is used to optimize the users' experience by customizing our web page content based on visitors' browser types and other information.<br>
        We will give you the option to accept or decline our usage of cookies when you visit our page. You can also change your browser settings to modify your Cookie preference.
      </p>

      <!-- start here if google ads used! -->
      <!-- <h3>Third-party advertising partners</h3>
      <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements
      and links that appear on TechVerse, which are sent directly to users' browser. They automatically receive your IP address when this occurs.
      These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you
      see on websites that you visit.<br>
      Our advertising partners are listed below. Each of our advertising
      partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
      </p>
      <ul>
          <li>
              <p>
                Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors
                based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART
                cookies by visiting the Google ad and content network Privacy Policy at the following
                URL – <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>. Ymay opt out of personalized advertising
                by Google at the following URL - <a href="https://adssettings.google.com/anonymous?hl=en">https://adssettings.google.com/anonymous?hl=en</a>.
              </p>
          </li>
      </ul>
      <p>Note that TechVerse has no access to or control over these cookies that are used by third-party advertisers.
        TechVerse's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies
        of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
      </p>
      <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers,
      it can be found at the browsers' respective websites.
      </p>
      -->
      <!-- all finishd -->
      <h3>Third Party Privacy Policies</h3>
      <p>
        This website may contain links to other third-party websites. By clicking on the links you leave our website, and will no longer receive our services.
        Thus, we do not review, have any control over third-party websites and cannot be responsible for their content or usage of your personal data.
        We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personal information.
      </p>

      <h3>Children's Information</h3>
      <p>
        We doe not knowingly collect any Personal Identifiable Information from children under the age of thirteen. If you think that your child provided
        this kind of information on our website, we strongly encourage you to contact us immediately. We will do our best efforts to remove children's
        information from our systems.
      </p>

      <h3>Privacy Policy Updates</h3>
      <p>
        We may update our Privacy Policy from time to time for any possible reasons. We will publish our updates to privacy policy at
        <a href="/privacy">https://haniftadjik.com/privacy</a>.
        However, you are responsible to read and check our privacy policy periodaically.
      </p>

      <h3>Our contact information</h3>
      <p>Responsible for the collection, processing, and use of your personal information through the Services is:</p>
      <ul style="list-style:square;">
        <li>Hanif Tadjik</li>
        <li>Website-url / address: https://HanifTadjik.com</li>
        <li>Email address: hanif.tadjik@hotmail.com </li>
      </ul>

      <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

      <h3>Consent</h3>
      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
      <br>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  props: {
  },
  created () {},
  data () {
    return {}
  }
}
</script>

<style scoped>
* {
  margin: auto;
  padding: 1% 0;
  text-align: left;
  width: 85%;
}
h1 {
  padding: 5% 0 0 0;
}
</style>
